<template>
  <div class="retread">
    <base-page-header
      title="Retread"
      subtitle="Find loans eligible for refinance"
    />
    <base-page-content>
      <template #content>
        <div class="retread-body mt-3">
          <div class="retread-filters pe-md-4">
            <base-tabs :tabs="tabs" :activeTab="activeTab" @set-tab="setTab" />
            <paging
              :page="currentPage"
              :paging="paging"
              @limitChanged="pagingUpdated"
              @pageChanged="pagingUpdated"
              class="ps-3 py-3 py-md-0 ps-md-0"
            />
          </div>
          <div class="retread-sub-filters">
            <div class="row">
              <div class="col-md-4">
                <div
                  class="page-filtration__search"
                  :class="{
                    'page-filtration__search_active': ui.showSearchField
                  }"
                >
                  <button
                    class="btn page-filtration__button"
                    :class="{
                      'page-filtration__button_disabled': ui.showSearchField
                    }"
                    @click="toggleSearchField"
                  >
                    <img src="@/assets/icons/icon-search.svg" alt="" />
                  </button>
                  <div class="form-group">
                    <label for="searchField">
                      <input
                        type="text"
                        id="searchField"
                        class="form-control"
                        @focusout="hideInput"
                        v-model="searchField"
                        ref="searchField"
                        @input="search"
                        placeholder=""
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-4 p-0">
                    <div class="d-flex align-items-center justify-content-between justify-content-md-end ps-3 ps-md-0">
                      <span class="font-weight-bold d-inline-block me-3">Loan Type:</span>
                      <multiselect
                        id="pastMonths"
                        v-model="filter.loanType"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="filter.loanTypes"
                        :searchable="false"
                        :show-labels="false"
                        @select="search"
                        label="name"
                        placeholder="All loan types"
                        track-by="value"/>
                    </div>
                  </div>
                  <div class="col-md-4 p-0">
                    <div class="d-flex align-items-center justify-content-between justify-content-md-end ps-3 ps-md-0">
                      <span class="font-weight-bold d-inline-block me-3">Months Past:</span>
                      <multiselect
                        id="pastMonths"
                        v-model="filter.pastMonths"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="filter.pastMonthsList"
                        :searchable="false"
                        :show-labels="false"
                        @select="search"
                        label="name"
                        placeholder="All Months"
                        track-by="value"/>
                    </div>
                  </div>
                  <div class="col-md-4 p-0">
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between justify-content-md-end
                        mt-3 mt-md-0
                        ps-3 ps-md-0
                        pe-md-3
                      "
                    >
                      <span class="font-weight-bold d-inline-block me-3">Rate:
                      </span>
                      <div class="custom-dropdown me-3 rouded-dropdown" name="rate">
                        Greater than {{ filter.rate }}
                        <img src="@/assets/icons/icon-dropdown-expand.svg" alt=""/>
                      </div>
                      <tippy
                        to="rate"
                        trigger="click"
                        arrowType="round"
                        boundary="window"
                        interactive="true"
                        animateFill="false"
                        distance="5"
                        placement="bottom"
                        zIndex="99"
                        hideOnClick="true"
                        theme="light"
                        class="custom-dropdown__tippy"
                        sticky="true"
                      >
                        <div class="row custom-dropdown__body">
                          <div class="table-menu-block rate">
                            <label for="">Rate Greater than</label>
                            <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">=></span>
                              </div>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="Username"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                v-model="filter.rate"
                              />
                            </div>

                            <button class="btn bg-green w-100" @click="search">
                              Apply
                            </button>
                          </div>
                        </div>
                      </tippy>
                    </div>
                  </div>
                </div>
                <div class="d-flex"></div>
              </div>
            </div>
          </div>
          <page-spinner v-if="ui.loading" class="mb-4" />
          <div class="table-block" v-else>
            <table class="table">
              <thead>
                <tr>
                  <th class="ps-4">Loan</th>
                  <th>Agent</th>
                  <th>Loan type</th>
                  <th>Rate</th>
                  <th>Term</th>
                  <th>Months Past</th>
                  <th></th>
                  <th class="text-right pe-3">Date Funded</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(funding, index) in fundings" :key="index">
                  <td
                    class="ps-4 cursor-pointer"
                    @click="openDetailsModal(funding)"
                  >
                    <div class="loan-name">
                      {{ funding.borrower }} ({{
                        convertToCurrency(funding.loanAmount)
                      }})
                    </div>
                  </td>
                  <td class="ps-0">{{ funding.agent }}</td>
                  <td class="ps-0">{{ funding.loanType }}</td>
                  <td class="ps-0">
                    {{ parseFloat(funding.rate).toFixed(3) }}%
                  </td>
                  <td class="ps-0">{{ funding.productAndTerm }}</td>
                  <td class="ps-0">
                    <div
                      class="months-past"
                      :class="
                        getMonthCount(funding.wireDate) >= 6
                          ? 'bg-green'
                          : 'default'
                      "
                    >
                      {{ getMonthCount(funding.wireDate) }}+ months
                    </div>
                  </td>
                  <td class="ps-0">
                    <div class="d-flex hide-cell">
                      <a
                        :href="'tel:' + funding.phone"
                        class="btn btn-outline-dark me-2"
                        :class="{ disabled: !funding.phone }"
                        >Call</a
                      >
                      <button
                        class="btn btn-outline-dark me-2"
                        :disabled="!funding.phone"
                        @click="openSmsModal(funding)"
                      >
                        Text
                      </button>
                      <a
                        :href="'mailto:' + funding.email"
                        class="btn btn-outline-dark"
                        :class="{ disabled: !funding.email }"
                        >Email</a
                      >
                    </div>
                  </td>
                  <td class="text-right pe-3 position-relative w-25">
                    <div
                      class="hide-cell hide-cell-end d-flex justify-content-end"
                    >
                      <button
                        class="btn btn-outline-dark me-2"
                        @click="reOriginate(funding)"
                        :disabled="getMonthCount(funding.wireDate) < 6"
                      >
                        Re-Originate
                      </button>
                      <button
                        v-if="activeTab === 'Hidden'"
                        class="btn btn-outline-dark me-2"
                        @click="unHide(funding)"
                      >
                        Unhide
                      </button>
                      <div
                        v-else
                        class="custom-dropdown me-3"
                        :name="'dropdown' + index"
                      >
                        Hide
                        <div class="custom-dropdown__triangle"></div>
                      </div>
                      <tippy
                        v-if="activeTab === 'Retread'"
                        :to="'dropdown' + index"
                        trigger="click"
                        arrowType="round"
                        boundary="window"
                        interactive="true"
                        animateFill="false"
                        distance="5"
                        placement="bottom"
                        zIndex="99"
                        hideOnClick="true"
                        theme="light"
                        class="custom-dropdown__tippy"
                        sticky="true"
                      >
                        <div class="row custom-dropdown__body">
                          <div class="table-menu-block">
                            <ul>
                              <li
                                :class="
                                  index === 0
                                    ? 'action-label'
                                    : 'cursor-pointer'
                                "
                                v-for="(val, index) in filter.hideFor"
                                :key="index"
                                @click="hideDataFor(funding, val)"
                              >
                                {{ val.name }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </tippy>
                    </div>
                    <span class="hide-hover">{{
                      formatDate(funding.wireDate)
                    }}</span>
                  </td>
                </tr>
                <tr v-if="fundings.length === 0">
                  <td colspan="8">
                    <div
                      class="col-12 modal-alert modal-alert_advice text-center"
                    >
                      No Results Found
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="pagination-footer">
            <paging
              :page="currentPage"
              :paging="paging"
              @limitChanged="pagingUpdated"
              @pageChanged="pagingUpdated"
            />
          </div>
        </div>
      </template>
    </base-page-content>
    <transition mode="out-in" name="component-fade">
      <retread-details-modal
        v-if="ui.details"
        :hideFor="filter.hideFor"
        :funding="selectedFunding"
        :activeTab="activeTab"
        @close="closeDetailsModal"
        @update="updateDetailsModal"
      />

      <sms-modal
        v-if="ui.sendSms"
        :funding="selectedFunding"
        @close="closeSmsModal"
      />
    </transition>
  </div>
</template>

<script>
import BasePageContent from "../../components/BasePageContent.vue";
import BasePageHeader from "../../components/BasePageHeader.vue";
import BaseTabs from "../../components/BaseTabs.vue";
import Paging from "../../components/Paging.vue";
import Multiselect from "vue-multiselect";
import PageSpinner from "../../components/pageSpinner";
import RetreadDetailsModal from "./modals/RetreadDetailsModal.vue";
import SmsModal from "./modals/SmsModal.vue";
import debounce from "@/utils/debounce";
import moment from "moment";

export default {
  name: "Retread",
  components: {
    BasePageHeader,
    BasePageContent,
    BaseTabs,
    Paging,
    Multiselect,
    PageSpinner,
    RetreadDetailsModal,
    SmsModal
  },
  data() {
    return {
      tabs: [
        { title: "Retread", count: 0 },
        { title: "Hidden", count: 0 }
      ],
      activeTab: "Retread",
      searchField: "",
      ui: {
        loading: false,
        showSearch: false,
        showSearchField: false,
        details: false,
        sendSms: false
      },
      filter: {
        pastMonths: { name: "6+ Months", value: "6" },
        pastMonthsList: [
          { name: "All Months", value: "0" },
          { name: "5+ Months", value: "5" },
          { name: "6+ Months", value: "6" },
          { name: "8+ Months", value: "8" },
          { name: "12+ Months", value: "12" }
        ],
        rate: 2.45,
        hideFor: [
          { name: "Hide for", value: "" },
          { name: "1 month", value: "1" },
          { name: "2 months", value: "2" },
          { name: "3 months", value: "3" },
          { name: "6 months", value: "6" },
          { name: "Forever", value: "0" }
        ],
        loanType: { name: "All", value: null },
        loanTypes: [
          { name: "All", value: null },
          { name: "Conventional", value: "Conventional" },
          { name: "Conventional Hi-Bal", value: "Conventional Hi-Bal" },
          { name: "FHA", value: "FHA" },
          { name: "VA", value: "VA" },
          { name: "Non-QM", value: "Non-QM" },
          { name: "JUMBO", value: "JUMBO" },
          { name: "USDA/FSA/FHS", value: "USDA/FSA/FHS" },
          { name: "HELOC", value: "HELOC" },
          { name: "Fixed Lien", value: "Fixed Lien" },
          { name: "DSCR/business purpose", value: "DSCR/business purpose" },
        ],
      },
      fundings: [],
      currentPage: 1,
      paging: {
        offset: 0,
        limit: 10,
        total: 0
      },
      search: debounce(() => {
        this.paging.offset = 0;
        this.getData();
      }, 350),
      selectedFunding: Object
    };
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/retreads", {
          params: {
            query: this.searchField,
            product: this.filter.loanType.value,
            months: this.filter.pastMonths.value,
            rate: this.filter.rate,
            activeTab: this.activeTab.toLowerCase(),
            offset: this.paging.offset,
            limit: this.paging.limit
          }
        })
        .then(res => {
          this.fundings = res.data.data;
          this.paging = res.data.paging;

          this.tabs.map(el => {
            if (el.title == this.activeTab) {
              el.count = this.paging.total;
            }
            return el;
          });
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loading = false;
        });
    },
    getCounts() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/retreads/counts", {
          params: { rate: this.filter.rate }
        })
        .then(res => {
          this.tabs = [
            { title: "Retread", count: res.data.retread },
            { title: "Hidden", count: res.data.hidden }
          ];

          this.getData();
        })
        .catch(err => {
          this.alertError(err.response.statusText);
          this.ui.loading = false;
        });
    },
    setTab(tab) {
      this.activeTab = tab.title;
      this.currentPage = 1;
      this.paging = {
        offset: 0,
        limit: 10,
        total: 0
      };
      this.search();
    },
    openDetailsModal(funding) {
      this.selectedFunding = funding;
      this.ui.details = true;
    },
    closeDetailsModal() {
      this.selectedFunding = Object;
      this.ui.details = false;
    },
    openSmsModal(funding) {
      this.selectedFunding = funding;
      this.ui.sendSms = true;
    },
    closeSmsModal() {
      this.selectedFunding = Object;
      this.ui.sendSms = false;
    },
    toggleSearchField() {
      this.ui.showSearchField = true;
      this.$refs.searchField.focus();
    },
    hideInput() {
      this.$refs.searchField.blur();
      this.ui.showSearchField = false;
    },
    pagingUpdated(paging) {
      this.paging = paging;
      this.currentPage = paging.currentPage;
      this.getData();
    },
    hideDataFor(funding, val) {
      if (val.value) {
        this.ui.loading = true;
        this.$http
          .get(`/api/v1/retread/${funding.id}/hide-for`, {
            params: { value: parseInt(val.value) }
          })
          .then(() => {
            this.getCounts();
          })
          .catch(err => {
            this.alertError(err.response.statusText);
            this.ui.loading = false;
          });
      }
    },
    reOriginate(funding) {
      if (funding.id && confirm("Are you sure?")) {
        this.ui.loading = true;
        this.$http
          .get(`/api/v1/retread/${funding.id}/re-originate`)
          .then(res => {
            this.alertSuccess(res.data.message);
          })
          .catch(err => {
            this.alertError(err.response.statusText);
          })
          .finally(() => {
            this.ui.loading = false;
          });
      }
    },
    unHide(funding) {
      if (funding.id && confirm("Are you sure?")) {
        this.ui.loading = true;
        this.$http
          .get(`/api/v1/retread/${funding.id}/unhide`)
          .then(() => {
            this.getCounts();
          })
          .catch(err => {
            this.alertError(err.response.statusText);
            this.ui.loading = false;
          });
      }
    },
    getMonthCount(val) {
      let month = parseInt(
        moment(new Date()).diff(new Date(val), "months", true)
      );
      return month > 0
        ? month
        : moment(new Date())
            .diff(new Date(val), "months", true)
            .toFixed(2);
    },
    updateDetailsModal(activeTab) {
      this.activeTab = activeTab;
      this.getCounts();
    }
  },
  beforeMount() {
    this.getCounts();
  }
};
</script>

<style lang="scss" scoped>
.retread {
  &-body {
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    background-color: #ffffff;
  }
  &-filters {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
  &-sub-filters {
    padding: 0 18px;
    margin-top: 16px;
    margin-bottom: 16px;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    &::v-deep {
      .multiselect {
        height: 32px;
        min-height: 32px;
        width: fit-content;
        min-width: 150px;
        @media screen and (max-width: 568px) {
          width: 100%;
          min-width: fit-content;
          max-width: fit-content;
        }

        &.multiselect--active {
          .multiselect__tags {
            transition: all 0.3s;
            border: 1px solid rgba(38, 111, 229, 0.5);
            box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
          }
        }

        &__tags {
          min-height: 32px;
          max-height: 32px;
          padding: 6px 40px 0 15px;
          transition: all 0.3s;
          border-radius: 16px;
          max-width: 150px;
          &:hover {
            border: 1px solid rgba(38, 111, 229, 0.2);
            box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
          }
        }

        &__select {
          min-height: 32px;
          max-height: 32px;

          &:before {
            top: 10%;
            position: relative;
            right: 0;
            color: #999;
            border: solid rgba(0, 0, 0, 0.46);
            margin-top: 4px;
            border-width: 0px 1px 1px 0;
            display: inline-block;
            padding: 3px;
            content: "";
            transform: rotate(44deg);
          }
        }

        &__single {
          color: rgba(0, 0, 0, 0.46);
          @media screen and (max-width: 568px) {
            font-size: 9px;
            white-space: nowrap;
          }
        }

        &__option {
          padding: 10px 12px;
          min-height: 32px;
          transition: all 0.3s;
          line-height: 20px;
          font-size: 14px;
          color: #000;
          @media screen and (max-width: 568px) {
            font-size: 9px;
          }

          &--highlight {
            background: #f3f3f3;
            line-height: 20px;
            font-size: 14px;
            color: #000;
            @media screen and (max-width: 568px) {
              font-size: 9px;
            }
          }
        }
      }
    }
  }
  .table {
    thead {
      tr {
        th {
          color: #000000;
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 24px;
          opacity: 1;
        }
      }
    }
    tbody {
      tr {
        transition: all 0.3s;
        &:hover {
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
          .hide-cell {
            opacity: 1;
          }
          .hide-hover {
            display: none;
          }
        }
        td {
          .loan-name {
            color: #0056ff;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 21px;
          }
          .hide-cell {
            opacity: 0;
            transition: all 0.3s;
            a {
              display: flex;
              justify-content: center;
              align-items: center;

              &.disabled {
                pointer-events: none;
                opacity: 0.65;
              }
            }
          }
          .hide-cell-end {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          .months-past {
            display: flex;
            align-items: center;
            width: fit-content;
            height: 30px;
            padding: 6px 12px;
            border-radius: 15px;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
            &.default {
              border: 1px solid rgba(0, 0, 0, 0.7);
              opacity: 0.7;
              opacity: 0.7;
              color: rgba($color: #000000, $alpha: 0.7);
            }
            &.bg-green {
              background-color: #009a52;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
.page-filtration__search {
  position: relative;
  min-width: 20px;
  display: flex;
  align-items: center;
  height: 30px;
  min-height: 30px;

  .form-group {
    position: absolute;
    opacity: 0;
    z-index: -1;
    top: 0;
    width: 0;
    transition: all 0.3s;
    height: 32px;
    min-height: 32px;

    input {
      height: 32px;
      min-height: 32px;
      border-radius: 16px;
      padding-left: 35px;
      font-sise: 13px;

      &::placeholder {
        font-size: 13px;
      }
    }
  }

  &_active {
    min-width: 160px;
    .form-group {
      z-index: 0;
      min-width: 160px;
      opacity: 1;
    }
  }
}
.page-filtration__button {
  position: relative;
  z-index: 2;
  top: -1px;

  &.page-filtration__button_disabled {
    cursor: unset;
    pointer-events: none;
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.font-weight-bold {
  font-weight: 600;
}
.text-right {
  text-align: right;
}
.pagination-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 14px 24px;
}
.custom-dropdown {
  height: 36px;
  width: 87px;
  border: 1px solid #8a8a8a;
  border-radius: 8px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;
  &.rouded-dropdown {
    border: 1px solid #cccccc;
    border-radius: 16px;
    min-width: 160px;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    padding: 0 12px;
  }
  &__btn-action {
    height: 100%;
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 11px;
    background: none;
    padding: 10px 19px;
    border-width: 0 1px 0 0;
    border-right: 1px solid #8a8a8a;
  }

  &__btn-trigger {
    height: 100%;
    background: none;
    border: none;
    padding: 0 7px;
  }

  &__triangle {
    width: 0;
    height: 0;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid transparent;
    border-top: 4.5px solid #000000;
    margin-right: 5px;
  }

  &__body {
    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-size: 13px;
    }
  }
}
.table-menu-block {
  padding: 0;
  min-width: 120px;
  &.rate {
    min-width: 150px;
    text-align: left;
    padding: 5px 10px;
    label {
      color: #000000;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
    }
    .input-group {
      width: 100%;
      max-width: 135px;
      border: 1px solid #d4d4d4;
      border-radius: 8px;
      background-color: #ffffff;
      &-prepend {
        border: none;
      }
      &-text {
        border: none;
        padding-right: 3px;
      }
      input {
        border: none;
        padding: 0;
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 15px;
        opacity: 1;
        &:focus,
        &:active {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
  li {
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }
}
.btn-outline-dark {
  border: 1px solid #8a8a8a;
  border-radius: 8px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
