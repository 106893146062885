<template>
  <div>
    <div class="modal">
      <div class="modal-bg" @click="$emit('close')"></div>
      <div class="modal-block" v-if="ui.loading">
        <page-spinner />
      </div>
      <div class="modal-block" v-else>
        <div class="modal-header">
          <div class="d-flex justify-content-between w-100 align-items-center">
            <div class="d-flex align-items-center">
              <h3>
                {{ funding.borrower }} ({{
                  convertToCurrency(funding.loanAmount)
                }})
              </h3>
              <div
                class="months"
                :class="
                  getMonthCount(funding.wireDate) >= 6 ? 'bg-green' : 'default'
                "
              >
                {{ getMonthCount(funding.wireDate) }}+ months
              </div>
            </div>
            <div class="d-flex hide-cell">
              <a
                :href="'tel:' + funding.phone"
                class="btn btn-outline-dark me-2"
                :class="{ disabled: !funding.phone }"
                >Call</a
              >
              <button class="btn btn-outline-dark me-2" :disabled="!funding.phone">
                Text
              </button>
              <a
                :href="'mailto:' + funding.email"
                class="btn btn-outline-dark"
                :class="{ disabled: !funding.email }"
                >Email</a
              >
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="modal-body-container border-0 pb-0">
            <div class="modal-body-container_title mt-3">Borrower</div>
            <div class="table-block">
              <table class="table">
                <tbody>
                  <tr>
                    <td>Borrower</td>
                    <td>{{ displayNoneIfEmpty(funding.borrower) }}</td>
                  </tr>
                  <tr>
                    <td>Co-Borrower</td>
                    <td>{{ displayNoneIfEmpty(funding.coBorrower) }}</td>
                  </tr>
                  <tr>
                    <td>Agent</td>
                    <td>{{ displayNoneIfEmpty(funding.agent) }}</td>
                  </tr>
                  <tr>
                    <td>Credit Score</td>
                    <td>{{ displayNoneIfEmpty(funding.creditScore) }}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{{ displayNoneIfEmpty(formatUSNumber(funding.phone)) }}</td>
                  </tr>
                  <tr>
                    <td>Email Address</td>
                    <td>{{ displayNoneIfEmpty(funding.email) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-body-container border-0 pb-0">
            <div class="modal-body-container_title mt-3">Loan Information</div>
            <div class="table-block">
              <table class="table">
                <tbody>
                  <tr>
                    <td>Date Funded</td>
                    <td>{{ formatDate(funding.wireDate) }}</td>
                  </tr>
                  <tr>
                    <td>Product and Term</td>
                    <td>{{ displayNoneIfEmpty(funding.productAndTerm) }}</td>
                  </tr>
                  <tr>
                    <td>Rate</td>
                    <td>{{ funding.rate }}%</td>
                  </tr>
                  <tr>
                    <td>Loan Amount</td>
                    <td>{{ convertToCurrency(funding.loanAmount) }}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{{ getMonthCount(funding.wireDate) >= 6 ? '' : 'Not ' }}Eligible for Refinance</td>
                  </tr>
                  <tr>
                    <td>Lender</td>
                    <td>{{ displayNoneIfEmpty(funding.lender) }}</td>
                  </tr>
                  <tr>
                    <td>Occupancy</td>
                    <td>{{ displayNoneIfEmpty(funding.occupancy) }}</td>
                  </tr>
                  <tr>
                    <td>Propery Address</td>
                    <td>{{ displayNoneIfEmpty(funding.propertyAddress) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button
            class="btn btn-primary"
            @click="reOriginate(funding)"
            :disabled="ui.saving || getMonthCount(funding.wireDate) < 6"
          >
            <span
              v-if="ui.saving"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-else> Re-Originate </span>
          </button>
          <button
            v-if="activeTab === 'Hidden'"
            class="btn btn-outline-dark me-2"
            @click="unHide(funding)"
          >
            Unhide
          </button>
          <div class="custom-dropdown" name="dropdown" v-else>
            Hide
            <div class="custom-dropdown__triangle"></div>
          </div>
          <tippy
            v-if="activeTab === 'Retread'"
            to="dropdown"
            trigger="click"
            arrowType="round"
            boundary="window"
            interactive="true"
            animateFill="false"
            distance="5"
            placement="top"
            zIndex="999999"
            hideOnClick="true"
            theme="light"
            class="custom-dropdown__tippy"
            sticky="true"
          >
            <div class="row custom-dropdown__body">
              <div class="table-menu-block">
                <ul>
                  <li
                    :class="index === 0 ? 'action-label' : 'cursor-pointer'"
                    v-for="(val, index) in hideFor"
                    :key="index"
                    @click="hideDataFor(funding, val)"
                  >
                    {{ val.name }}
                  </li>
                </ul>
              </div>
            </div>
          </tippy>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSpinner from "../../../components/pageSpinner";
import moment from "moment";

export default {
  name: "RetreadDetailsModal",
  components: { PageSpinner },
  props: {
    funding: Object,
    hideFor: Array,
    activeTab: String
  },
  data() {
    return {
      ui: {
        loading: false,
        saving: false
      }
    };
  },
  methods: {
    getData() {
      this.ui.loading = true;
      setTimeout(() => {
        this.ui.loading = false;
      }, 1000);
    },
    getMonthCount(val) {
      let month = parseInt(
        moment(new Date()).diff(new Date(val), "months", true)
      );
      return month > 0
        ? month
        : moment(new Date())
            .diff(new Date(val), "months", true)
            .toFixed(2);
    },
    hideDataFor(funding, val) {
      if (val.value && confirm("Are you sure?")) {
        this.ui.loading = true;
        this.$http
          .get(`/api/v1/retread/${funding.id}/hide-for`, {
            params: { value: parseInt(val.value) }
          })
          .then(() => {
            this.$emit("update", "Hidden");
          })
          .catch(err => {
            this.alertError(err.response.statusText);
          })
          .finally(() => {
            this.ui.loading = false;
          });
      }
    },
    reOriginate(funding) {
      if (funding.id && confirm("Are you sure?")) {
        this.ui.saving = true;
        this.$http
          .get(`/api/v1/retread/${funding.id}/re-originate`)
          .then(res => {
            this.alertSuccess(res.data.message);
          })
          .catch(err => {
            this.alertError(err.response.statusText);
          })
          .finally(() => {
            this.ui.saving = false;
          });
      }
    },
    unHide(funding) {
      if (funding.id && confirm("Are you sure?")) {
        this.ui.loading = true;
        this.$http
          .get(`/api/v1/retread/${funding.id}/unhide`)
          .then(() => {
            this.$emit("update", "Retread");
          })
          .catch(err => {
            this.alertError(err.response.statusText);
          })
          .finally(() => {
            this.ui.loading = false;
          });
      }
    }
  },
  beforeMount() {
    this.getData();
  }
};
</script>

<style lang="scss" scoped>
.table-block {
  overflow: initial;

  table {
    table-layout: fixed;
    white-space: initial !important;
    width: 100%;
    tbody {
      tr {
        min-height: 100%;
        max-height: 100%;
        td {
          min-height: 100%;
          max-height: 100%;
          width: fit-content;
          word-wrap: break-word;
          &:first-child {
            width: 30%;
          }
        }
      }
    }
  }
}
.custom-dropdown {
  height: 36px;
  width: 87px;
  border: 1px solid #8a8a8a;
  border-radius: 8px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;
  &__btn-action {
    height: 100%;
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 11px;
    background: none;
    padding: 10px 19px;
    border-width: 0 1px 0 0;
    border-right: 1px solid #8a8a8a;
  }

  &__btn-trigger {
    height: 100%;
    background: none;
    border: none;
    padding: 0 7px;
  }

  &__triangle {
    width: 0;
    height: 0;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid transparent;
    border-top: 4.5px solid #000000;
    margin-right: 5px;
  }

  &__body {
    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-size: 13px;
    }
  }
}
.table-menu-block {
  padding: 0;
  min-width: 120px;
  li {
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }
}
.months {
  padding: 6px 12px;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  border-radius: 15px;
  background-color: #009a52;
  margin-left: 10px;
}
.modal-header {
  .hide-cell {
    a {
      display: flex;
      justify-content: center;
      align-items: center;

      &.disabled {
        pointer-events: none;
        opacity: 0.65;
      }
    }
  }
}
</style>
