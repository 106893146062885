<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <span class="loan-file">Send Sms</span>
        <h3>{{ funding.borrower }}</h3>
        <button class="btn btn-control close-btn" @click="$emit('close')">
          <img src="@/assets/icons/icon-close.png" alt="" />
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group full-width">
          <label for="fieldSms" class="mb-2">Message</label>
          <textarea
            id="fieldSms"
            cols="30"
            rows="20"
            placeholder="Write a message here..."
            class="form-control"
            v-model="formData.message"
          >
          </textarea>
          <div class="char-count">
            Characters: {{ formData.message.length }}
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <base-button title="Send" action="secondary-default" :disabled="ui.saving || !formData.message.length"
                     :loading="ui.saving" @click-btn="sendSms" />
        <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue';
export default {
  components: { BaseButton },
  name: "SmsModal",
  props: {
    funding: Object
  },
  data() {
    return {
      ui: {
        saving: false
      },
      formData: {
        message: ""
      }
    };
  },
  methods: {
    sendSms() {
      this.ui.saving = true;
      let formData = new FormData();
      formData.append("message", this.formData.message.trim());
      formData.append("to", this.funding.phone);

      this.$http
        .post(`/api/v1/retread/${this.funding.id}/send-sms`, formData)
        .then(res => {
          this.alertSuccess(res.data.message);
          this.$emit('close');
        })
        .catch(err => {
          this.alertError(err.response.data ? err.response.data.message : err.response.statusText);
        })
        .finally(() => {
          this.ui.saving = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.modal {
  &-block {
    height: auto !important;
    margin-top: 50px !important;
    border-radius: 8px;
  }

  &-footer {
    position: absolute;
  }

  .char-count {
    margin-top: 8px;
    opacity: 0.46;
    color: #000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
    text-align: right;
  }
}
</style>
